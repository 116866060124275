import React from "react";
import styles from "./styles.scss"
import {useHistory} from "react-router-dom";
import routes from "../../../routes";
import Logo from "../Logo";

const Header = ({currentPage, backgroundImage, black, white, projectName, duration, role}) => {
    let history = useHistory();

    const handleOnClick = (url) => {
        history.push(url);
    }

    return (
        <div
            className={`Header ${backgroundImage ? 'withBackground' : ''} ${black ? 'black' : ''} ${white ? 'white' : ''}`}
            style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className={'HeaderTop'}>
                <div className={'LogoContainer'} onClick={() => handleOnClick(routes.landingPage)}>
                    <Logo/>
                    <button className={`HeaderButton ${currentPage === routes.landingPage && 'active'}`} onClick={() => handleOnClick(routes.landingPage)}>About Me</button>
                </div>
                <div className={'Menu'}>
                </div>
            </div>
            {projectName && <h1 className={'ProjectName'}>{projectName}</h1>}
            {(duration || role) && <div className={'HeaderBottom'}>
                <div>
                    {role && <span><strong>Role:</strong> {role}</span>}
                </div>
                <div>
                    {duration && <span><strong>Duration:</strong> {duration}</span>}
                </div>
                <div>
                </div>
            </div>}
        </div>
    )
}

export default Header;