import React from "react";
import ProjectsButton from "./ProjectButton";
import styles from './styles.scss'

const ProjectsSlide = ({projects}) => {
    return (
        <div className={'ProjectsSlide'}>
            {projects.map(project => <ProjectsButton project={project} />)}
        </div>
    )
}

export default ProjectsSlide;