import React, {useEffect} from "react";
import TextBox from "../../ProjectPage/TextBox";
import Image from "../../ProjectPage/Image";
import Questions from "../../ProjectPage/Questions";
import Persona from "../../ProjectPage/Persona";
import Header from "../../shared/Header";
import TwoColumns from "../../shared/TwoColumns";
import styles from './styles.scss';
import scrollToTop from "../../../utils/scrollToTop";

const RMM = () => {

    useEffect(() => {
        scrollToTop();
    }, []);

    return <div>
        <Header backgroundImage={'/assets/images/cover-rmm.png'} black duration={'Sep - Oct 2021'}
                role={'UX/UI Designer'}
                projectName={'Remember My Medicines'}/>

        <div className={'Content'}>
            <TwoColumns>
                <div>
                    <h2>Project Vision</h2>
                    <p><strong>Remember My Medicines</strong> is a cross-platform tool that help young adults to
                        remember to
                        take medicines on time. The typical user is between 18-65 years old, and most users are people
                        that
                        need to take their meds on time. </p>
                </div>
                <div>
                    <h2>Challenges</h2>
                    <p>Create a mobile app that is user-friendly by providing clear navigation and offering a fast
                        process
                        for reminders with notification.</p>
                </div>
            </TwoColumns>

            <Image url={'/assets/images/DesktopMobile.RMM.png'}/>

            <TextBox withBackground>
                <h2>Kickoff</h2>
                <p>In this project, I conducted <strong>user interviews</strong> which I then turned into empathy maps
                    to
                    better understand
                    the target user and their needs. I have found that some users struggle to take periodic medications
                    when
                    they need them because they have more commitments during the day or for their distractions. However,
                    many mobile apps that remember the users to take medicine are confusing to navigate, creating
                    inconvenience for users. I have tried to improve their user experience and make them cross-platform.
                </p>
                <Questions questions={[
                    'What is the product and who is it for?', 'What do our users need most?', 'What challenges could we face moving forward?', 'Who do we see as our biggest competitors?'
                ]}/>
            </TextBox>

            <TextBox>
                <h2>Affinity Diagram</h2>
                <p>I used an <strong>affinity diagram</strong> to separate the data into groups of tasks which were
                    further
                    categorized by
                    high-level goals for improvement in efficiency, process, depth, and familiarity. Recognizing the
                    conflicts of interest from each audience allowed me to focus on shaping user goals.</p>
                <Image url={'/assets/images/affinity diagram.rmm.png'}/>
            </TextBox>

            <TwoColumns withBackground title={'Users'}>
                <div>
                    <Persona url={'/assets/images/meredith.png'} name={'Primary'}>
                        <li><strong>Name:</strong> Meredith</li>
                        <li><strong>Age:</strong> 23</li>
                        <li><strong>Occupation:</strong> Customer service responsive</li>
                    </Persona>
                    <p>She is a working student who needs to take her meds on time because she won't have any health
                        problems.</p>
                </div>
                <div>
                    <Persona url={'/assets/images/olga.png'} name={'Secondary'}>
                        <li><strong>Name:</strong> Olga</li>
                        <li><strong>Age:</strong> 42</li>
                        <li><strong>Occupation:</strong> Business consultation</li>
                    </Persona>
                    <p>She is a business consultant who needs a way to remind her daughter to take medicine when she
                        needs
                        it because being a little disorderly. Olga would like to make she more autonomous.</p>
                </div>
            </TwoColumns>


            <TextBox>
                <div>
                    <h2>Competitive Analysis</h2>
                    <p>I created a <strong>competitive audit</strong> examining several potential competitor and I found
                        out
                        none have a cross-platform app. The majority of the features between competitors were very
                        similar,
                        the main differences that I noticed were: </p>
                    <p> ~ Easily Accessible vs Hardly Accessible</p>
                    <p> ~ Too Many Steps vs Simplified Interaction</p>
                    <p> ~ Distracting Interface vs Minimalistic Interface</p>
                </div>
                <Image url={'/assets/images/audit.rmm.png'}/>
            </TextBox>

            <TextBox withBackground>
                <div>
                    <h2>Sitemap</h2>
                    <p>I've built a <strong>user flow</strong> of what a basic journey looks like from start to finish.
                        This helps me understand the ways that users can interact with the
                        product, as well as allowing me to see navigation through the user's goals.</p>
                    <Image url={'/assets/images/journeymap.rmm.png'}/>
                </div>
            </TextBox>

            {/* <Image url={'/assets/images/img.png'}/> */}

            <TextBox>
                <h2>Wireframe</h2>
                <p>After sketching out some <strong>paper wireframes</strong> and thinking through the preliminary flow,
                    I
                    reviewed what was necessary or unnecessary and what areas needed improvement. I poured a lot of time
                    into this step to make sure I had the finishing touches before moving on to the visuals.</p>
            </TextBox>

            <Image url={'/assets/images/wireframe-rmm.png'}/>

            <TextBox withBackground>
                <div>
                    <h2>Interaction</h2>
                    <p>After creating my prototype from low fidelity wireframes, I asked 5 participants to run through
                        different scenarios in my prototype in hope of garnering enough <strong>feedback</strong> to
                        use for my next set of design interaction.
                    </p>
                </div>
            </TextBox>

            <TextBox>
                <h2>Mockups</h2>
                <p>Based on insights from usability studies, I applied design changes such as easily creating a reminder
                    and sharing it with whoever the user want. Additional design changes included accessibility to
                    medicines
                    monitoring over time.</p>
            </TextBox>

            <Image url={'/assets/images/mockup.rmm.png'}/>

            <TextBox withBackground>
                <h2>Design System</h2>
                <Image url={'/assets/images/ds.rmm.png'}/>
            </TextBox>


            <Image url={'/assets/images/secondmockap.rmm.png'}/>

            <TextBox>
                <h2>Takeaways</h2>
                <p>I created this app during my course Google UX Designer on Coursera. I learned that even a small
                    design change can have a huge impact on the user
                    experience. The most important takeaway for me is to always focus on the real needs of the user when
                    coming up with design ideas and solutions.
                </p>
            </TextBox>

        </div>

    </div>
}

export default RMM;

