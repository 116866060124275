import React, {useEffect, useState} from "react";
import scrollToTop from "../../../utils/scrollToTop";
import styles from "./styles.scss"
import Arrow from "../Arrow";


const ScrollButton = () => {
    const [show, setShow] = useState(false);

    const handleNavigation = (e) => {
        const scroll = e.currentTarget.scrollY
        scroll > 1000 ? setShow(true) : setShow(false)
    };

    useEffect(() => {
        window.addEventListener("scroll", (e) => handleNavigation(e));
        return () => { // return a cleanup function to unregister our function since its gonna run multiple times
            window.removeEventListener("scroll", (e) => handleNavigation(e));
        };
    }, []);


    return show ? <div className={'ScrollButton'} onClick={() => scrollToTop(true)}><Arrow/></div> : null
}


export default ScrollButton;

