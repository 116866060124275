import React, {useEffect} from "react";
import TextBox from "../../ProjectPage/TextBox";
import Image from "../../ProjectPage/Image";
import Questions from "../../ProjectPage/Questions";
import Persona from "../../ProjectPage/Persona";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import TwoColumns from "../../shared/TwoColumns";
import styles from './styles.scss';
import scrollToTop from "../../../utils/scrollToTop";

const AdoptMe = () => {

    useEffect(() => {
        scrollToTop();
    }, []);

    return <div>
        <Header backgroundImage={'/assets/images/cover-am.png'} black duration={'Aug - Sep 2021'}
                role={'UX/UI Designer'}
                projectName={'Adopt Me'}/>


        <div className={'Content'}>
            <TwoColumns>
                <div>
                    <h2>Project Vision</h2>
                    <p><strong>Adopt Me</strong> is a platform for the adoption of dogs. The typical user is between
                        18-55
                        years old and most users are people that need some company or that choose to adopt dogs offering
                        them a new life.</p>
                </div>
                <div>
                    <h2>Challenges</h2>
                    <p>Create a website that is user-friendly by providing clear navigation and offering a fast process
                        for dogs
                        adoption.</p>
                </div>
            </TwoColumns>

            <Image url={'/assets/images/desktop-mobile-am.png'}/>

            <TextBox withBackground>
                <h2>Kickoff</h2>
                <p>In this project, I conducted <strong>user interviews</strong>, which I then turned into empathy maps
                    to
                    better understand the target user and their needs. I discovered that some users need a dog to
                    improve
                    their state of stress or depression. However, many adoption websites are confusing to navigate,
                    creating
                    inconvenience to users. I then have tried to improve their user experience.</p>
                <Questions questions={[
                    'What is the product and who is it for?', 'What do our users need most?', 'What challenges could we face moving forward?', 'Who do we see as our biggest competitors?'
                ]}/>
            </TextBox>

            <TextBox>
                <h2>Affinity Diagram</h2>
                <p>I used an <strong>affinity diagram</strong> to separate the data into groups of tasks which were
                    further
                    categorized by
                    high-level goals for improvement in efficiency, process, depth, and familiarity. Recognizing the
                    conflicts of interest from each audience allowed me to focus on shaping user goals.</p>
                <Image url={'/assets/images/affinity diagram.am(2).png'}/>
            </TextBox>

            <TwoColumns withBackground title={'Users'}>
                <div>
                    <Persona url={'/assets/images/david.png'} name={'Primary'}>
                        <li><strong>Name:</strong> David</li>
                        <li><strong>Age:</strong> 26</li>
                        <li><strong>Occupation:</strong> Production worker</li>
                    </Persona>
                    <p>David is a busy professional and animal lover. He will soon go to live alone in a house in the
                        mountains and would like to adopt a puppy to share this new experience and to have a great
                        company.</p>
                </div>
                <div>
                    <Persona url={'/assets/images/agata.png'} name={'Secondary'}>
                        <li><strong>Name:</strong> Agata</li>
                        <li><strong>Age:</strong> 34</li>
                        <li><strong>Occupation:</strong> Pastry Chef</li>
                    </Persona>
                    <p>She lives with her daughter in California and they have a great relationship, but his daughter is
                        very sad since her father died. For this Agata wants to give her a puppy, in particular, an
                        unloved puppy ready to be loved.</p>
                </div>
            </TwoColumns>

            <TextBox>
                <div>
                    <h2>Competitive Analysis</h2>
                    <p>I have created a <strong>competitive audit</strong> by examining several potential competitors
                        and
                        I have found that no one allows making an appointment online. Most of the features between
                        competitors were very similar,
                        the main differences I noticed were: </p>
                    <p> ~ Easily Accessible vs Hardly Accessible</p>
                    <p> ~ Too Many Steps vs Simplified Interaction</p>
                    <p> ~ Distracting Interface vs Minimalistic Interface</p>
                </div>
                <Image url={'/assets/images/audit.am.png'}/>
            </TextBox>


            <TextBox withBackground>
                <div>
                    <h2>Sitemap</h2>
                    <p>I created a user journey map to help identify possible pain points and
                        improvement opportunities. Difficulty with website navigation was a primary pain point for
                        users, so I used that knowledge to create a <strong>sitemap</strong>.</p>
                    <p>My goal here was to make strategic information architecture decisions that would improve overall
                        website navigation.</p>
                    <Image url={'/assets/images/journeymap.am(2).png'}/>
                </div>
            </TextBox>

            {/* <Image url={'/assets/images/img.png'}/> */}

            <TextBox>
                <h2>Wireframe</h2>
                <p>Moving from paper to digital wireframes made it easy to
                    understand how the redesign could help address users pain points and improve user experience.
                    Prioritizing useful button locations and visual element placement on the home page was a key part of
                    my strategy.
                </p>
            </TextBox>

            <Image url={'/assets/images/wireframe-am.png'}/>

            <TextBox withBackground>
                <div>
                    <h2>Interaction</h2>
                    <p>After creating my prototype from low fidelity wireframes, I asked 5 participants to run through
                        different scenarios in my prototype in hopes of garnering enough <strong>feedback</strong> to
                        use for my next set of design interaction.
                    </p>
                </div>
            </TextBox>

            <TextBox>
                <h2>Mockups</h2>
                <p>Based on the insights from the usability study, I made some changes to improve the adoption flow.
                    Another change I made was the addition of the puppy location. This allowed users to choose the puppy
                    based on their distance.</p>
                <p>Another change I made was the addition of the "filter" menu. This gave more freedom to users
                    filtering adoptions based on their preferences.</p>
            </TextBox>

            <Image url={'/assets/images/mockup(2).png'}/>

            <TextBox>
                <h2>Design System</h2>
                <Image url={'/assets/images/Designsystem.am.png'}/>
            </TextBox>

            <Image url={'/assets/images/secondmockups.am.png'}/>

            <TextBox>
                <h2>Takeaways</h2>
                <p>I created this app during my Google UX Designer course on Coursera. Adopt Me is very close and dear
                    to my heart because I love animals and I personally had the opportunity to give a better life to a
                    puppy. I learned to understand what the problem was and this helped me find workable and
                    useful solutions for a great user experience.
                </p>
            </TextBox>

        </div>
    </div>
}

export default AdoptMe;

