import React from 'react';
import styles from "./styles.scss"

const TwoColumns = ({withBackground, title, children}) => {

    return <div className={`TwoColumns ${withBackground ? 'WithBackground' : ''}`}>
        {title && <h2 className={'Title'}>{title}</h2>}
        <div className={'Content'}>
            {children}
        </div>
    </div>
}

export default TwoColumns;