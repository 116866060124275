import React from "react";

const Logo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 115 115">
            <defs>
                <filter id="Rettangolo_117" x="0" y="0" width="115" height="115" filterUnits="userSpaceOnUse">
                    <feOffset dx="2" dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood floodOpacity="0.2"/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Logo" transform="translate(5.5 5.988)">
                <g id="Raggruppa_219" data-name="Raggruppa 219" transform="translate(0 -0.488)">
                    <g transform="matrix(1, 0, 0, 1, -5.5, -5.5)" filter="url(#Rettangolo_117)">
                        <rect id="Rettangolo_117-2" data-name="Rettangolo 117" width="100" height="100" rx="5" transform="translate(5.5 5.5)" fill="none"  strokeMiterlimit="10" strokeWidth="3"/>
                    </g>
                    <g id="Raggruppa_218" data-name="Raggruppa 218" transform="translate(7.748 12.137)">
                        <path id="Tracciato_213" data-name="Tracciato 213" d="M22.836,29.761A277.154,277.154,0,0,0,52.7,74.343c1.831,2.251,5-.96,3.181-3.181A271.232,271.232,0,0,1,26.722,27.5c-1.366-2.566-5.252-.285-3.887,2.266Z" transform="translate(2.635 1.89)" fill="#383838"/>
                        <path id="Tracciato_214" data-name="Tracciato 214" d="M47.292,81.008c2.806-11.39,3.9-23.114,5.9-34.656A121.885,121.885,0,0,1,57.151,29.58c1.5-4.727,3.346-11.1,9.274-11.146a2.287,2.287,0,0,0,0-4.574c-6.092,0-9.769,4.345-11.9,9.7a99.323,99.323,0,0,0-5.057,18.19C47.037,54.4,46.032,67.3,42.956,79.864c-.705,2.866,3.631,4.086,4.337,1.22Z" transform="translate(12.675 -5.462)" fill="#383838"/>
                        <path id="Tracciato_215" data-name="Tracciato 215" d="M56.183,8.844A114.044,114.044,0,0,1,25.9,29.176c-4.952,2.266-10.294,3.9-14.556,7.413A15.456,15.456,0,0,0,5.9,51.25a15.186,15.186,0,0,0,12,12c2.821.555,4.037-3.781,1.2-4.337a10.729,10.729,0,0,1-8.9-9.379c-.465-5.132,2.656-9,6.9-11.419s9.409-4.142,14-6.407a114.884,114.884,0,0,0,13.955-8.163A120.961,120.961,0,0,0,59.394,12.07c2.116-1.981-1.08-5.162-3.2-3.181Z" transform="translate(-5.663 -8.263)" fill="#383838"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Logo;