import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import './App.scss';
import LandingPage from "../LandingPage";
import routes from "../../routes";
import WhenItDrop from "../../components/Projects/WhenItDrop";
import ManaMoney from "../../components/Projects/ManaMoney";
import RMM from "../../components/Projects/RMM";
import AdoptMe from "../../components/Projects/Adopt Me";
import ScrollButton from "../../components/shared/ScrollButton";

function App() {
    return (
        <>
            <ScrollButton />
            <Router>
                <Switch>
                    <Route exact path={routes.landingPage}>
                        <LandingPage/>
                    </Route>
                    <Route path={routes.projectWhenItDrop}>
                        <WhenItDrop/>
                    </Route>
                    <Route path={routes.projectManamoney}>
                        <ManaMoney/>
                    </Route>
                    <Route path={routes.projectRMM}>
                        <RMM/>
                    </Route>
                    <Route path={routes.projectAdoptMe}>
                        <AdoptMe/>
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

export default App;
