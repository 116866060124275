import React from "react";
import {useHistory} from "react-router-dom";
import styles from './../styles.scss'

const ProjectsButton = ({project: {logo, title, role, url}}) => {
    let history = useHistory();

    const handleOnClick = () => {
        history.push(url);
    }

    // Da Usare {title} {role}
    return (
        <div className={'ProjectsButton'} onClick={handleOnClick} style={{ backgroundImage: `url(${logo})`}}>
            <div className={'ProjectDesc'}>
                <div className={'Title'}>{title}</div>
                <div className={'Role'}>{role}</div>
            </div>
        </div>
    )
}

export default ProjectsButton;