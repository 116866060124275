import React, {useEffect} from "react";
import CenteredText from "../../components/shared/CenteredText";
import TwoColumns from "../../components/shared/TwoColumns";
import TextBox from "../../components/LandingPage/TextBox";
import ProjectsSlide from "../../components/LandingPage/ProjectsSlide";
import Header from "../../components/shared/Header";
import routes from "../../routes";
import styles from "./styles.scss"
import Image from "../../components/ProjectPage/Image";
import scrollToTop from "../../utils/scrollToTop";
import ExternalLink from "../../components/shared/ExternalLink";

const LandingPage = () => {

    const projects = [
        {
            logo: '/assets/images/LP-RMM.png',
            title: 'Remember My Medicines',
            role: 'UX/UI Designer',
            url: routes.projectRMM
        },
        /*
        {
            logo: '/assets/images/Freelencer.png',
            title: 'Freelencer',
            role: 'UX/UI Designer',
            url: routes.projectRMM
        },
         */
        {
            logo: '/assets/images/adoptme_logo.png',
            title: 'Adopt Me',
            role: 'UX/UI Designer',
            url: routes.projectAdoptMe
        },
        {
            logo: '/assets/images/LP-WID.png',
            title: 'WhenItDrop',
            role: 'UX/UI Designer',
            url: routes.projectWhenItDrop
        }
        /* {
             logo: '/assets/images/LP-MM.png',
             title: 'ManaMoney',
             role: 'UX/UI Designer',
             url: routes.projectManamoney
         },*/
    ];

    useEffect(() => {
        scrollToTop();
    }, []);

    return <div>
        <Header currentPage={routes.landingPage}/>

        <div className={'Content'}>
            <CenteredText withBackground={true}>
                <div className={'MainText'}>Hello~, I'm Tatiana.</div>
                <div className={'MainDescription'}>I'm passionate about <span>UX/UI</span> design.</div>
                <div className={'MainDescription'}>I love to realize the digital product that solves user experience
                    problems.
                </div>
            </CenteredText>

            <TwoColumns>
                <div>
                    <Image url={'/assets/images/TV(aboutme).png'} />
                </div>
                <div>
                    <p>
                        I am a <strong>Product Designer</strong> with over four years of
                        experience, specializing in creating intuitive and
                        engaging products. I have led design projects from
                        conception to realization, collaborating with
                        multidisciplinary teams to translate user needs into
                        innovative digital solutions. I have worked in a
                        dynamic international company, supporting the team
                        with the creation of a Global Design System. This
                        experience has refined my design skills and
                        deepened my understanding of the global market
                        and the cultural nuances in design strategy.
                    </p>
                    <div>
                        <button onClick={() => window.open('/assets/images/CV-Tatiana Vantaggiato.pdf', '_blank')}>Resume</button>
                    </div>
                    <div>
                        <h2>CONTACT ME!</h2>
                        <p>Searching for a designer that shows passion in their work?
                            Interested in working together?
                            I'd love to hear from you!</p>
                    </div>
                    <div>
                        <ExternalLink name={'LinkedIn'} icon={'/assets/images/linkedin.svg'} url={'https://www.linkedin.com/in/tatiana-vantaggiato/'}/>
                        {/* <ExternalLink name={'Email'} icon={'/assets/images/email.svg'} url={'mailto:'}/>*/}
                        <ExternalLink name={'Instagram'} icon={'/assets/images/instagram.svg'} url={'https://www.instagram.com/uxui.tatiana/'}/>
                        <ExternalLink name={'Dribbble'} icon={'/assets/images/dribbble.svg'} url={'https://dribbble.com/Vantaggiato'}/>
                    </div>
                </div>
            </TwoColumns>

            <CenteredText withBackground={true}>
                <div className={'MainText Portfolio'}>~ <span>M</span>Y <span>P</span>ORTFOLIO ~</div>
            </CenteredText>

            <ProjectsSlide projects={projects}/>
        </div>
    </div>
}

export default LandingPage;

