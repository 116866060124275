import React from 'react';
import style from './styles.scss'

const Image = ({url}) => {
    return (
        <div className={'Image'}>
            <img src={url}/>
        </div>
    )
}

export default Image;