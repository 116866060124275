import React from 'react';
import style from './styles.scss'

const Persona = ({url, name, children}) => {
    return (
        <div className={'Persona'}>
            <div className={'Avatar'} style={{backgroundImage: `url(${url})`}} />
            <div className={'Description'}>
                <h4>{name}</h4>
                {children}
            </div>
        </div>
    )
}

export default Persona;