import React from 'react';
import styles from './styles.scss'

const TextBox = ({children, withBackground}) => {
    return (
        <div className={`TextBox ${withBackground ? 'WithBackground' : ''}`}>
            <div className={'Content'}>
                {children}
            </div>
        </div>
    )
}

export default TextBox;