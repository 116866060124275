import React, {useEffect} from "react";
import TextBox from "../../ProjectPage/TextBox";
import Image from "../../ProjectPage/Image";
import Questions from "../../ProjectPage/Questions";
import Persona from "../../ProjectPage/Persona";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import TwoColumns from "../../shared/TwoColumns";
import styles from './styles.scss';
import scrollToTop from "../../../utils/scrollToTop";

const WhenItDrop = () => {

    useEffect(() => {
        scrollToTop();
    }, []);

    return <div>
            <Header backgroundImage={'/assets/images/cover.wid.png'} black duration={'Mar - Sep 2020'}
                    role={'UX/UI Designer'}
                    projectName={'WhenItDrop'}/>

        <div className={'Content'}>
            <TwoColumns>
                <div>
                    <h2>Project Vision</h2>
                    <p><strong>WhenItDrop</strong> is a Web app that tracks Amazon's products prices, alerting users in real-time when a product price decrease.</p>
                </div>
                <div>
                    <h2>Challenges</h2>
                    <p>Create a minimal user interface focused on product search and management of the notifications of each followed product.</p>
                </div>
            </TwoColumns>

            <Image url={'/assets/images/desktop-mobile-wid.png'}/>

            <TextBox withBackground>
                <h2>Kickoff</h2>
                <p>In this project, I conducted <strong>user interviews</strong>, which I then turned into empathy maps to better understand the target user and their needs.
                    I found that some users don’t get in time to take advantage of discounts dedicated to the products they are interested in.</p>
                <p>I designed a cross-platform a app that allows users to search products and add them to a list of favorite products. After that, the users will receive a notification every time that a price drop occurs.</p>
                <Questions questions={[
                    'What is the product and who is it for?', 'What do our users need most?', 'What challenges could we face moving forward?', 'Who do we see as our biggest competitors?'
                ]}/>
            </TextBox>

            <TextBox>
                <h2>Affinity Diagram</h2>
                <p>I used an <strong>affinity diagram</strong> to discover the user's needs and this led me to an improvement in user experience, outlining their goals.</p>
                <Image url={'/assets/images/affinity diagram.wid.png'}/>
            </TextBox>

            <TwoColumns withBackground title={'Users'}>
                <div>
                    <Persona url={'/assets/images/luis.png'} name={'Primary'}>
                        <li><strong>Name:</strong> Luis</li>
                        <li><strong>Age:</strong> 29</li>
                        <li><strong>Occupation:</strong> Software engineer</li>
                    </Persona>
                    <p>Luis works hard in a company in his city. After a day of work, he likes to play video games to relieve the stress. Being a fan of gaming and collector, he would like to buy the PlayStation 5 but unfortunately, it’s hard to find.</p>
                </div>
                <div>
                    <Persona url={'/assets/images/paola.png'} name={'Secondary'}>
                        <li><strong>Name:</strong> Paola</li>
                        <li><strong>Age:</strong> 40</li>
                        <li><strong>Occupation:</strong> Teacher</li>
                    </Persona>
                    <p>She loves two things in her life: children and cooking. Due to various commitments, during the day she does not cook much and she would like to buy a kitchen robot to be faster.</p>
                </div>
            </TwoColumns>

            <TextBox>
                <div>
                    <h2>Competitive Analysis</h2>
                    <p>I created an <strong>audit competitive</strong>, examining two potential competitors, and I discovered that their characteristics are similar:</p>
                    <p> ~ Paid version / Free version</p>
                    <p> ~ Difficult to access</p>
                    <p> ~ Distracting Interface </p>
                    <p> ~ Requires browser plug-in </p>
                </div>
                <Image url={'/assets/images/audit.wid.png'}/>
            </TextBox>


            <TextBox withBackground>
                <div>
                    <h2>Sitemap</h2>
                    <p>I created a journey map to identify possible points of pain and improvement opportunities. Difficulty with website navigation, together with notification settings, were points of pain for most users.</p>
                    <p>My goal was to make strategic information architecture decisions that would improve overall website navigation.</p>
                    <p>Later, I created a <strong>sitemap</strong> to list all website's pages.</p>
                    <Image url={'/assets/images/journeymap.wid.png'}/>
                </div>
            </TextBox>

            {/* <Image url={'/assets/images/img.png'}/> */}

            <TextBox>
                <h2>Wireframe</h2>
                <p>During the transition from paper to digital <strong>wireframe</strong>, it was easy to understand how design could help address user pain points and improve user experience. Making a minimal product card was one of my main goals.</p>
            </TextBox>

            <Image url={'/assets/images/wireframe-wid.png'}/>

            <TextBox withBackground>
                <div>
                    <h2>Interaction</h2>
                    <p>After creating my prototype from low fidelity wireframes, I asked 5 users to try the web app in hopes of garnering enough <strong>feedback</strong> to use for my next set of design interactions.</p>
                </div>
            </TextBox>

            <TextBox>
                <h2>Mockups</h2>
                <p>Based on the insights from the usability study, I made some changes to improve the tracking of amazon's products prices. One of the changes I made was the addition of the <strong>"desired price"</strong> option in the product card. This allowed users to receive a notification only when the price of the product was less than or equal to the one desired.</p>
                <p>Another change I made was to give the user the option to choose easily the <strong>"notification options"</strong>, within the product card (New or Used and Amazon or Amazon and Third Parties).</p>
            </TextBox>

            <Image url={'/assets/images/mockup.wid.png'}/>

            <TextBox>
                <h2>Design System</h2>
                <Image url={'/assets/images/designsystem.wid.png'}/>
            </TextBox>

            <Image url={'/assets/images/secondmockups.wid.png'}/>

            <TextBox>
                <h2>Takeaways</h2>
                <p><strong>Whenitdrop</strong> was my first web app designed at the beginning of this journey into the world of UX/UI Design. I had the opportunity to work with founder of the project, which has managed the development part.</p>
                <p>This allowed me, not only to improve my knowledge of the sector but also to understand how important it is to collaborate with a developer even after that the project was handed over to him. </p>
            </TextBox>

        </div>
    </div>
}

export default WhenItDrop;