import React, {useEffect} from "react";
import TextBox from "../../ProjectPage/TextBox";
import Image from "../../ProjectPage/Image";
import Questions from "../../ProjectPage/Questions";
import Persona from "../../ProjectPage/Persona";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import TwoColumns from "../../shared/TwoColumns";
import styles from './styles.scss';
import scrollToTop from "../../../utils/scrollToTop";

const ManaMoney = () => {

    useEffect(() => {
        scrollToTop();
    }, []);

    return <div>
        <Header/>

        <div className={'Content'}>

            <TwoColumns>
                <div>
                    <h3>Project Vision</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias corporis fugiat libero minus
                        nesciunt
                        quam quidem sapiente sequi voluptas voluptates.</p>
                </div>
                <div>
                    <h3>Challenges</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias corporis fugiat libero minus
                        nesciunt
                        quam quidem sapiente sequi voluptas voluptates.</p>
                </div>
            </TwoColumns>

            <Image url={'/assets/images/img.png'}/>

            <TextBox withBackground>
                <h3>Project Vision</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias corporis fugiat libero minus nesciunt
                    quam quidem sapiente sequi voluptas voluptates.</p>
                <Questions questions={[
                    'Pippo fa la cacca?', 'Ma quanta ne fa?', 'Sul serio?', 'Wow?!'
                ]}/>
            </TextBox>

            <TextBox>
                <h3>Project Vision</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias corporis fugiat libero minus nesciunt
                    quam quidem sapiente sequi voluptas voluptates.</p>
            </TextBox>

            <Image url={'/assets/images/img.png'}/>


            <TwoColumns withBackground title={'Pippo Baudo!'}>
                <div>
                    <Persona url={'/assets/images/img.png'} name={'Primary'}>
                        <li>Abcdsd</li>
                        <li>Abcdsd</li>
                        <li>Abcdsd</li>
                        <li>Abcdsd</li>
                    </Persona>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias corporis fugiat libero minus
                        nesciunt
                        quam quidem sapiente sequi voluptas voluptates.</p>
                </div>
                <div>
                    <Persona url={'/assets/images/img.png'} name={'Secondary'}>
                        <li>Abcdsd</li>
                        <li>Abcdsd</li>
                        <li>Abcdsd</li>
                        <li>Abcdsd</li>
                    </Persona>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias corporis fugiat libero minus
                        nesciunt
                        quam quidem sapiente sequi voluptas voluptates.</p>
                </div>
            </TwoColumns>

        </div>
    </div>
}

export default ManaMoney;