import React from 'react';
import styles from './styles.scss'

const Questions = ({questions}) => {
    return (
        <div className={'Questions'}>
            {questions.map(question => <div>{question}</div>)}
        </div>
    )
}

export default Questions;